import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import {
	FormControl,
	FormLabel,
	FormGroup,
	TextField,
	Grid,
	Avatar,
	Typography,
	InputAdornment,
	IconButton,
	ClickAwayListener,
	FormHelperText,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { DateTimePicker } from '@material-ui/pickers'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { useSelector } from 'react-redux'
import BoardContext from '../BoardContext'

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		width: 246,
		margin: '0 auto 10px',
		// padding: theme.spacing(1, 0),
		'& .MuiCardContent-root': {
			padding: theme.spacing(1),
		},
		'& .MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(1),
		},
		'& .MuiCardActions-root': {
			justifyContent: 'space-around',
		},
		'& .MuiButton-label': {
			textTransform: 'capitalize',
		},
		'& .MuiFormControl-marginDense': {
			marginTop: '1px',
		},
	},
	textField: {
		width: '100%',
		'& .MuiFormLabel-root': {
			fontSize: '12px',
			lineHeight: 2,
		},
		'& .MuiOutlinedInput-input': {
			fontSize: '14px',
		},
		'& .MuiAutocomplete-root': {
			'& .MuiTextField-root': {
				marginTop: 0,
			},
		},
	},
	optionTextEllipsis: {
		width: `100%`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	userPic: {
		width: 36,
		height: 36,
		marginRight: theme.spacing(1),
		fontSize: 17,
	},
	userInfo: { maxWidth: `calc(100% - (36px + 8px))` },
	mainGrid: {
		flexWrap: 'nowrap',
	},
	assigneeDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
	},
	text: {
		fontSize: 14,
		flex: 1,
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	titleField: {
		margin: 0,
	},
	checkboxSec: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: '2px',
		'& .MuiTypography-body1': {
			color: theme.palette.almostBlack[600],
			fontSize: 12,
		},
		'& .PrivateSwitchBase-root-95': {
			padding: theme.spacing(0, 1, 0, 1.4),
		},
		'& .MuiButtonBase-root': {
			padding: '3px',
		},
	},
}))

const StickySchema = Yup.object().shape({
	project_title: Yup.string().trim().max(255, 'Task title should not be more than 255 characters.').required('Please enter the Task Title'),
	due_date: Yup.date().nullable().min(new Date(), 'Due date cannot be in the past.'),
	is_approval: Yup.boolean(),
	report_to: Yup.object()
		.nullable()
		.when('is_approval', {
			is: true,
			then: Yup.object().nullable().required('Please select reporting to'),
			otherwise: null,
		}),
})

const NewCardForm = React.forwardRef((props, ref) => {
	const { glassMembers, newCardFormSubmit, glassStickyGroup, token } = useContext(BoardContext)
	const { onCancel, laneId } = props
	const classes = useStyles()
	const filterOptions = createFilterOptions({
		stringify: ({ first_name, last_name, email }) => `${first_name} ${last_name} ${email}`,
	})

	const formik = useFormik({
		initialValues: {
			project_title: '',
			assign_to: null,
			due_date: null,
			report_to: null,
			is_approval: false,
			sticky_group: null,
		},
		onSubmit: (e) => {
			newCardFormSubmit(e, laneId, onCancel)
		},
		enableReinitialize: true,
		validationSchema: StickySchema,
	})
	// const token = useSelector((state) => _.get(state, 'session.authToken'))
	const [isAssigneeListOpen, setIsAssigneeListOpen] = useState(false)
	const [isReportingListOpen, setIsReportingListOpen] = useState(false)
	const [isStickyGroupListOpen, setIsStickyGroupListOpen] = useState(false)

	const cancelForm = () => {
		onCancel()
	}

	const toggleAssigneeList = () => {
		setIsAssigneeListOpen(!isAssigneeListOpen)
	}

	const toggleReportingList = () => {
		setIsReportingListOpen(!isReportingListOpen)
	}

	const toggleStickyGroupList = () => {
		setIsStickyGroupListOpen(!isStickyGroupListOpen)
	}

	return (
		<form ref={ref} onSubmit={formik.handleSubmit}>
			<Card className={classes.cardContainer}>
				<CardContent>
					<FormGroup>
						<FormControl className={classes.textField} variant='outlined' margin='dense' required>
							<FormLabel component='legend'>Title</FormLabel>
							<TextField
								variant='outlined'
								id='project_title'
								margin='dense'
								name='project_title'
								type='text'
								autoFocus
								placeholder='Enter Title'
								className={classes.titleField}
								value={formik.values.project_title}
								error={formik.errors.project_title}
								helperText={formik.errors.project_title && formik.errors.project_title}
								onChange={(e) => {
									formik.handleChange(e)
								}}
							/>
						</FormControl>
					</FormGroup>
					<FormGroup>
						<FormControl className={classes.textField} variant='outlined' margin='dense'>
							<FormLabel component='legend'>{'Due Date & Time'}</FormLabel>
							<DateTimePicker
								name='due_date'
								id='due_date'
								className={classes.textField}
								autoOk
								fullWidth
								size='small'
								variant='dialog'
								inputVariant='outlined'
								format='DD MMM YYYY, h:mm A'
								value={_.get(formik.values, 'due_date', null)}
								disablePast
								clearable
								error={formik.errors.due_date}
								placeholder='Choose Due Date'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton>
												<DateRangeIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
								onChange={(newDate) => {
									if (newDate) {
										newDate.set({ seconds: 0, milliseconds: 0 })
									}
									formik.handleChange({
										target: {
											name: 'due_date',
											value: newDate,
										},
									})
								}}
							/>
							{formik.errors.due_date && <FormHelperText error>{formik.errors.due_date}</FormHelperText>}
						</FormControl>
					</FormGroup>
					<FormGroup>
						<FormControl className={classes.textField} variant='outlined' margin='dense'>
							<FormLabel component='legend'>Assignee</FormLabel>
							{_.isEmpty(formik.values.assign_to) || isAssigneeListOpen ? (
								<ClickAwayListener onClickAway={toggleAssigneeList}>
									<Autocomplete
										fullWidth
										size='small'
										id='assign_to'
										placeholder='Select Assignee'
										filterOptions={filterOptions}
										onChange={(event, newValue) => {
											formik.setFieldValue('assign_to', newValue)
											toggleAssigneeList()
										}}
										openOnFocus
										value={formik.values.assign_to}
										options={glassMembers}
										getOptionSelected={(option, value) => _.isEqual(option?.user_id, value?.user_id)}
										// eslint-disable-next-line react/jsx-no-duplicate-props
										getOptionLabel={(option) => (option?.first_name && formTheName(option?.first_name, option?.last_name)) || ''}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												variant='outlined'
												size='small'
												id='assign_to'
												name='assign_to'
												placeholder='Select Assignee'
											/>
										)}
										renderOption={(option) => {
											const picUrl = _.get(option, 'profile_pic_url', '')
											const full_name = _.join([_.get(option, 'first_name', ''), _.get(option, 'last_name', '')], ' ')
											return (
												<Grid key={option.user_id} container alignItems='center' className={classes.mainGrid}>
													<Grid item>
														<Avatar
															alt={full_name}
															src={picUrl ? getImgUrl(token, picUrl) : null}
															size='small'
															className={classes.userPic}
															style={{ backgroundColor: stringToHslColor(full_name) }}
														>
															{full_name && full_name.substring(0, 1)}
														</Avatar>
													</Grid>
													<Grid item xs className={classes.userInfo}>
														<Typography variant='body2' className={classes.optionTextEllipsis} title={full_name}>
															{full_name}
														</Typography>
														<Typography variant='body2' color='textSecondary' className={classes.optionTextEllipsis} title={option.email}>
															{option.email}
														</Typography>
													</Grid>
												</Grid>
											)
										}}
									/>
								</ClickAwayListener>
							) : (
								<div
									role='button'
									onClick={toggleAssigneeList}
									style={{
										display: 'flex',
										flex: 1,
										alignItems: 'center',
									}}
								>
									<div className={classes.assigneeDiv} onClick={toggleAssigneeList}>
										<Avatar
											{...(_.isEmpty(formik.values?.assign_to?.profile_pic_url)
												? {}
												: {
														src: getImgUrl(token, formik.values?.assign_to?.profile_pic_url),
												  })}
											alt={formTheName(formik.values?.assign_to?.first_name, formik.values?.assign_to?.last_name)}
											className={classes.userPic}
											style={{
												backgroundColor: stringToHslColor(formTheName(formik.values?.assign_to?.first_name, formik.values?.assign_to?.last_name)),
											}}
										>
											{_.slice(_.words(formTheName(formik.values?.assign_to?.first_name, formik.values?.assign_to?.last_name)), 0, 1).reduce(
												(a, t) => (a += t[0]),
												''
											)}
										</Avatar>
										<Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
											{formTheName(formik.values?.assign_to?.first_name, formik.values?.assign_to?.last_name)}
										</Typography>
									</div>
								</div>
							)}
						</FormControl>
					</FormGroup>
					<FormGroup>
						<FormControl className={classes.textField} variant='outlined' margin='dense'>
							<FormLabel component='legend'>Reporting To</FormLabel>
							{_.isEmpty(formik.values.report_to) || isReportingListOpen ? (
								<ClickAwayListener onClickAway={toggleReportingList}>
									<Autocomplete
										fullWidth
										size='small'
										id='report_to'
										placeholder='Select Reporting To'
										filterOptions={filterOptions}
										onChange={(event, newValue) => {
											formik.setFieldValue('report_to', newValue)
											toggleReportingList()
										}}
										openOnFocus
										value={formik.values.report_to}
										options={glassMembers}
										getOptionSelected={(option, value) => _.isEqual(option?.user_id, value?.user_id)}
										// eslint-disable-next-line react/jsx-no-duplicate-props
										getOptionLabel={(option) => (option?.first_name && formTheName(option?.first_name, option?.last_name)) || ''}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												variant='outlined'
												size='small'
												id='report_to'
												name='report_to'
												placeholder='Select Reporting To'
												error={formik.errors.report_to}
												helperText={formik.errors.report_to}
											/>
										)}
										renderOption={(option) => {
											const picUrl = _.get(option, 'profile_pic_url', '')
											const full_name = _.join([_.get(option, 'first_name', ''), _.get(option, 'last_name', '')], ' ')
											return (
												<Grid key={option.user_id} container alignItems='center' className={classes.mainGrid}>
													<Grid item>
														<Avatar
															alt={full_name}
															src={picUrl ? getImgUrl(token, picUrl) : null}
															size='small'
															className={classes.userPic}
															style={{ backgroundColor: stringToHslColor(full_name) }}
														>
															{full_name && full_name.substring(0, 1)}
														</Avatar>
													</Grid>
													<Grid item xs className={classes.userInfo}>
														<Typography variant='body2' className={classes.optionTextEllipsis} title={full_name}>
															{full_name}
														</Typography>
														<Typography variant='body2' color='textSecondary' className={classes.optionTextEllipsis} title={option.email}>
															{option.email}
														</Typography>
													</Grid>
												</Grid>
											)
										}}
									/>
								</ClickAwayListener>
							) : (
								<div
									role='button'
									onClick={toggleReportingList}
									style={{
										display: 'flex',
										flex: 1,
										alignItems: 'center',
									}}
								>
									<div className={classes.assigneeDiv} onClick={toggleReportingList}>
										<Avatar
											{...(_.isEmpty(formik.values?.report_to?.profile_pic_url)
												? {}
												: {
														src: getImgUrl(token, formik.values?.report_to?.profile_pic_url),
												  })}
											alt={formTheName(formik.values?.report_to?.first_name, formik.values?.report_to?.last_name)}
											className={classes.userPic}
											style={{
												backgroundColor: stringToHslColor(formTheName(formik.values?.report_to?.first_name, formik.values?.report_to?.last_name)),
											}}
										>
											{_.slice(_.words(formTheName(formik.values?.report_to?.first_name, formik.values?.report_to?.last_name)), 0, 1).reduce(
												(a, t) => (a += t[0]),
												''
											)}
										</Avatar>
										<Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
											{formTheName(formik.values?.report_to?.first_name, formik.values?.report_to?.last_name)}
										</Typography>
									</div>
								</div>
							)}
						</FormControl>
						<div className={classes.checkboxSec}>
							<FormControlLabel
								control={
									<Checkbox
										checked={Boolean(formik?.values?.is_approval)}
										name='is_approval'
										onChange={(e) => {
											formik.setFieldValue('is_approval', !formik?.values?.is_approval)
										}}
										color='primary'
										size='small'
									/>
								}
								label='Does this Task need an approval?'
							/>
						</div>
					</FormGroup>
					<FormGroup>
						<FormControl className={classes.textField} variant='outlined' margin='dense'>
							<FormLabel component='legend'>Task Group</FormLabel>
							{_.isEmpty(formik.values.sticky_group) || isStickyGroupListOpen ? (
								<ClickAwayListener onClickAway={toggleStickyGroupList}>
									<Autocomplete
										fullWidth
										size='small'
										id='sticky_group'
										placeholder='Select Task Group'
										// filterOptions={filterOptions}
										onChange={(event, newValue) => {
											formik.setFieldValue('sticky_group', newValue)
											toggleStickyGroupList()
										}}
										openOnFocus
										value={formik.values.sticky_group}
										options={glassStickyGroup}
										getOptionSelected={(option, value) => _.isEqual(option?.sticky_group_id, value?.sticky_group_id)}
										// eslint-disable-next-line react/jsx-no-duplicate-props
										getOptionLabel={(option) => (option?.sticky_group && option?.sticky_group) || ''}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												variant='outlined'
												size='small'
												id='sticky_group'
												name='sticky_group'
												placeholder='Select Task Group'
											/>
										)}
										renderOption={(option) => {
											const picUrl = _.get(option, 'sticky_group_url', '')
											const name = _.get(option, 'sticky_group', '')
											return (
												<Grid key={option.sticky_group_id} container alignItems='center' className={classes.mainGrid}>
													<Grid item>
														<Avatar
															alt={name}
															src={picUrl ? getImgUrl(token, picUrl) : null}
															size='small'
															className={classes.userPic}
															style={{ backgroundColor: stringToHslColor(name) }}
														>
															{name && name.substring(0, 1)}
														</Avatar>
													</Grid>
													<Grid item xs className={classes.userInfo}>
														<Typography variant='body2' className={classes.optionTextEllipsis} title={name}>
															{name}
														</Typography>
													</Grid>
												</Grid>
											)
										}}
									/>
								</ClickAwayListener>
							) : (
								<div
									role='button'
									onClick={toggleStickyGroupList}
									style={{
										display: 'flex',
										flex: 1,
										alignItems: 'center',
									}}
								>
									<div className={classes.assigneeDiv} onClick={toggleStickyGroupList}>
										<Avatar
											{...(_.isEmpty(formik.values?.sticky_group?.sticky_group_url)
												? {}
												: {
														src: getImgUrl(token, formik.values?.sticky_group?.sticky_group_url),
												  })}
											alt={formik.values?.sticky_group?.sticky_group}
											className={classes.userPic}
											style={{
												backgroundColor: stringToHslColor(formik.values?.sticky_group),
											}}
										>
											{_.slice(_.words(formik.values?.sticky_group?.sticky_group), 0, 1).reduce(
												(a, t) => (a += t[0]),
												''
											)}
										</Avatar>
										<Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
											{formik.values?.sticky_group?.sticky_group}
										</Typography>
									</div>
								</div>
							)}
						</FormControl>
					</FormGroup>
				</CardContent>
				<CardActions>
					<Button size='small' fullWidth variant='contained' color='primary' type='submit' disableElevation>
						Stick It
					</Button>
					<Button size='small' fullWidth disableElevation onClick={cancelForm}>
						Cancel
					</Button>
				</CardActions>
			</Card>
		</form>
	)
})

export default NewCardForm
