import { Autocomplete, createFilterOptions, Rating } from '@material-ui/lab'
import { makeStyles, TextField, Avatar, Typography, Icon, Grid, FormHelperText, InputAdornment, IconButton } from '@material-ui/core'
import React, { useState } from 'react'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'
import _ from 'lodash'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { PRIORITY_LIST } from '../../../../../../../constants'
import { DateTimePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	newCardFormWrapper: {
		padding: theme.spacing(1),
		'& div[tabindex="-1"]:focus': {
			outline: 'none',
		},
	},
	invisible: {
		display: 'none',
	},
	container: {
		width: '100%',
		borderRadius: theme.spacing(0.5),
		padding: theme.spacing(1),
		transition: `border 250ms, box-shadow 250ms`,
		border: `1px solid ${theme.palette.almostBlack[400]}`,
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
	},
	titleSec: {
		fontSize: 14,
	},
	flexRow: {
		display: 'flex',
		// alignItems: 'flex-start',
		position: 'relative',
		flexDirection: 'column',
		'& .Mui-disabled': {
			color: theme.palette.text.secondary,
			backgroundColor: theme.palette.grey[100],
			fontSize: 14,
			padding: 3,
			borderRadius: 2,
			width: 220,
			'& fieldset': {
				border: 'none',
			},
			'& .MuiFormControl-marginDense': {
				marginTop: 0,
			},
		},
		[theme.breakpoints.down('sm')]: {
			display: 'inherit',
			'& .Mui-disabled': {
				width: '100%',
			},
		},
	},

	launcher: {
		cursor: 'pointer',
		'&:hover, &:hover $iconDiv .MuiIcon-root': {
			color: `${theme.palette.primary.main} !important`,
			transition: 'color 0.167s ease-in-out',
			'& $text': {
				color: theme.palette.primary.main,
			},
		},
	},
	textInput: {
		'& .MuiOutlinedInput-input': {
			fontSize: theme.typography.pxToRem(16),
			fontWeight: 500,
			paddingLeft: 8,
		},
	},
	blueText: {
		color: theme.palette.primary.main,
		transition: 'color 0.167s ease-in-out',
	},
	iconDiv: {
		display: 'flex',
		width: 24,
		height: 24,
		justifyContent: 'flex-start',
		alignItems: 'center',
		'& .MuiIcon-root': {
			width: 20,
			height: 20,
			lineHeight: `20px`,
			fontSize: 22,
			textAlign: 'left',
			marginRight: 4,
			color: `${theme.palette.almostBlack[700]}d6`,
		},
	},
	assigneeDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		marginTop: theme.spacing(1),
	},
	text: {
		fontSize: 14,
		flex: 1,
	},
	dateTime: {
		'& .MuiOutlinedInput-root': {
			fontSize: 14,
		},
		'& .MuiIcon-root': {
			fontSize: 16,
		},
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: 16,
		lineHeight: 'unset',
	},
	avatarSmall: {
		width: 24,
		height: 24,
		fontSize: 13,
		lineHeight: 'unset',
	},
	userPicSmall: {
		width: 24,
		height: 24,
		fontSize: 13,
	},
	userPic: {
		width: 32,
		height: 32,
		fontSize: 17,
		marginRight: theme.spacing(1),
	},
	optionTextEllipsis: {
		width: `100%`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	checkboxSec: {
		display: 'block',
		marginLeft: theme.spacing(0.5),
		// margin: theme.spacing(1, 0),
		'& .MuiTypography-body1': {
			color: theme.palette.almostBlack[600],
			fontSize: 12,
		},
		'& .PrivateSwitchBase-root-95': {
			padding: theme.spacing(0, 1, 0, 1.4),
		},
	},
	noneSection: {
		backgroundColor: theme.palette.grey[100],
		padding: 5,
		fontSize: 14,
		color: theme.palette.grey[600],
		marginTop: theme.spacing(1),
	},
}))

const StickyAutoComplete = ({ options, title, value, isPriority, isDueDate, onChange, name, error, disabled, isPane, isRating, panes }) => {
	const classes = useStyles()
	const [isAssigneeListOpen, setIsAssigneeListOpen] = useState(false)
	const token = useSelector((state) => _.get(state, 'session.authToken'))

	const toggleAssigneeList = () => {
		setIsAssigneeListOpen(!isAssigneeListOpen)
	}

	return (
		<div>
			<Typography variant='subtitle2' component='p' className={classes.titleSec}>
				{title}
			</Typography>
			<div className={classnames(classes.flexRow, classes.launcher)}>
				{isPriority ? (
					<PriorityAutocomplete
						title={title}
						value={value}
						disabled={disabled}
						onChange={onChange}
						isAssigneeListOpen={isAssigneeListOpen}
						toggleAssigneeList={toggleAssigneeList}
						token={token}
						classes={classes}
						name={name}
					/>
				) : isPane ? (
					<PaneAutocomplete
						title={title}
						value={value}
						disabled={disabled}
						onChange={onChange}
						isAssigneeListOpen={isAssigneeListOpen}
						toggleAssigneeList={toggleAssigneeList}
						token={token}
						classes={classes}
						name={name}
						panes={panes}
					/>
				) : isRating ? (
					<RatingForm
						title={title}
						value={value}
						disabled={disabled}
						// onChange={onChange}
						isAssigneeListOpen={isAssigneeListOpen}
						toggleAssigneeList={toggleAssigneeList}
						token={token}
						classes={classes}
						name={name}
					/>
				) : isDueDate ? (
					<DuedateForm
						error={error}
						title={title}
						value={value}
						disabled={disabled}
						onChange={onChange}
						isAssigneeListOpen={isAssigneeListOpen}
						toggleAssigneeList={toggleAssigneeList}
						name={name}
						classes={classes}
					/>
				) : (
					<AssigneeAutocomplete
						title={title}
						value={value}
						disabled={disabled}
						onChange={onChange}
						isAssigneeListOpen={isAssigneeListOpen}
						toggleAssigneeList={toggleAssigneeList}
						token={token}
						classes={classes}
						options={options}
						name={name}
						error={error}
					/>
				)}
			</div>
		</div>
	)
}

StickyAutoComplete.propTypes = {
	options: PropTypes.array,
	title: PropTypes.string,
	value: PropTypes.any,
	isPriority: PropTypes.bool,
	isDueDate: PropTypes.bool,
	onChange: PropTypes.func,
	name: PropTypes.string,
	error: PropTypes.any,
	disabled: PropTypes.bool,
}

const PaneAutocomplete = ({ value, onChange, isAssigneeListOpen, toggleAssigneeList, classes, name, disabled, panes }) => {
	console.log('value', value)
	// const panes = useSelector((state) => _.get(state, 'glass.settings.panes', {}))
	const paneOptions = _.map(panes, function (n) {	return {value : n.pane_id, name : n.pane_name} })
	let pane = value ? (typeof value === 'string' ? _.find(paneOptions, { value }) : value) : null

	return (
		<>
			{isAssigneeListOpen ? (
				<>
					<Autocomplete
						name={name}
						options={paneOptions}
						size={'small'}
						onBlur={toggleAssigneeList}
						disableClearable
						// popupIcon={null}
						fullWidth
						openOnFocus
						getOptionLabel={(option) => _.startCase(option.name)}
						getOptionSelected={(option, value) => option.value === value.value}
						renderOption={(option) => {
							return (
								<Grid key={option.value} className={classes.priorityItem} container alignItems='center'>
									<Grid item xs>
										<Typography variant='body2'>{option.name}</Typography>
									</Grid>
								</Grid>
							)
						}}
						filterSelectedOptions
						onChange={(e, v) => {
							onChange(e, v)
							toggleAssigneeList()
						}}
						value={pane}
						renderInput={(params) => <TextField autoFocus {...params} variant='outlined' />}
					/>
				</>
			) : (
				<div role='button' onClick={disabled ? null : toggleAssigneeList}>
					{!_.isEmpty(pane) ? (
						<Grid style={{ marginTop: 8 }} className={classes.priorityItem} container alignItems='center'>
							<Grid item xs>
								<Typography variant='body2'>{pane.name}</Typography>
							</Grid>
						</Grid>
					) : (
						<p className={classes.noneSection}>None</p>
					)}
				</div>
			)}
		</>
	)
}

const RatingForm = ({ value, onChange, isAssigneeListOpen, toggleAssigneeList, classes, name, disabled }) => {

	return (
		<>
				<div role='button' onClick={disabled ? null : toggleAssigneeList}>
					{!_.isEqual(value, 0) ? (
						<Grid style={{ marginTop: 8 }} className={classes.priorityItem} container alignItems='center'>
							<Grid item xs style={{display: 'flex', flexDirection: 'row'}}>
							<p style={{ marginTop: '0px', fontSize: '15px' }}>{value}</p>&nbsp;<Rating name="unique-rating" precision={0.1} value={value} readOnly size="small" />							
							</Grid>
						</Grid>
					) : (
						<p className={classes.noneSection}>None</p>
					)}
				</div>
		</>
	)
}

const PriorityAutocomplete = ({ value, onChange, isAssigneeListOpen, toggleAssigneeList, classes, name, disabled }) => {
	let priority = value ? (typeof value === 'string' ? _.find(PRIORITY_LIST, { value }) : value) : null

	return (
		<>
			{isAssigneeListOpen ? (
				<>
					<Autocomplete
						name={name}
						options={PRIORITY_LIST}
						size={'small'}
						onBlur={toggleAssigneeList}
						// popupIcon={null}
						fullWidth
						openOnFocus
						getOptionLabel={(option) => _.startCase(option.name)}
						getOptionSelected={(option, value) => option.value === value.value}
						renderOption={(option) => {
							return (
								<Grid key={option.value} className={classes.priorityItem} container alignItems='center'>
									<Grid item>
										<Icon style={{ color: option.color }} fontSize='small'>
											{option.icon}
										</Icon>
									</Grid>
									<Grid item xs>
										<Typography variant='body2'>{option.name}</Typography>
									</Grid>
								</Grid>
							)
						}}
						filterSelectedOptions
						onChange={(e, v) => {
							onChange(e, v)
							toggleAssigneeList()
						}}
						value={priority}
						renderInput={(params) => <TextField autoFocus {...params} variant='outlined' />}
					/>
				</>
			) : (
				<div role='button' onClick={disabled ? null : toggleAssigneeList}>
					{!_.isEmpty(priority) ? (
						<Grid style={{ marginTop: 8 }} className={classes.priorityItem} container alignItems='center'>
							<Grid item>
								<Icon style={{ color: priority.color }} fontSize='small'>
									{priority.icon}
								</Icon>
							</Grid>
							<Grid item xs>
								<Typography variant='body2'>{priority.name}</Typography>
							</Grid>
						</Grid>
					) : (
						<p className={classes.noneSection}>None</p>
					)}
				</div>
			)}
		</>
	)
}

PriorityAutocomplete.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	isAssigneeListOpen: PropTypes.bool,
	toggleAssigneeList: PropTypes.func,
	classes: PropTypes.object,
	name: PropTypes.string,
	disabled: PropTypes.bool,
}

const AssigneeAutocomplete = ({ value, onChange, isAssigneeListOpen, token, toggleAssigneeList, classes, options, name, disabled }) => {
	const filterOptions = createFilterOptions({
		stringify: ({ first_name, last_name, email }) => `${first_name} ${last_name} ${email}`,
	})
	return (
		<>
			{isAssigneeListOpen ? (
				<>
					<Autocomplete
						size='small'
						fullWidth
						options={options}
						disabled={disabled}
						name={name}
						filterOptions={filterOptions}
						onBlur={toggleAssigneeList}
						onChange={(e, v) => {
							onChange(e, v)
							toggleAssigneeList()
						}}
						getOptionSelected={(o, v) => _.isEqual(o?.user_id, v?.user_id)}
						value={value}
						getOptionLabel={(o) => (o?.first_name && formTheName(o?.first_name, o?.last_name)) || ''}
						openOnFocus
						renderInput={(params) => (
							<TextField
								size='small'
								variant='outlined'
								margin='dense'
								autoFocus
								inputProps={{ style: { paddingTop: 8 } }}
								placeholder='Type a name or email address'
								fullWidth
								{...params}
							/>
						)}
						renderOption={(o, s) => {
							return (
								<ListItem
									name={formTheName(o?.first_name, o?.last_name)}
									email={o?.email}
									isSelected={s?.selected}
									imgSrc={o?.profile_pic_url ? getImgUrl(token, o?.profile_pic_url) : null}
									key={`assignee_${o?.id}`}
								/>
							)
						}}
					/>
				</>
			) : (
				<div role='button' onClick={disabled ? null : toggleAssigneeList}>
					{!_.isEmpty(value) ? (
						<div className={classes.assigneeDiv}>
							<Avatar
								{...(!_.isEmpty(value?.profile_img) && {
									src: getImgUrl(token, value?.profile_img),
								})}
								alt={formTheName(value?.first_name, value?.last_name)}
								className={classes.userPicSmall}
								style={{
									backgroundColor: stringToHslColor(formTheName(value?.first_name, value?.last_name)),
								}}
							>
								{_.slice(_.words(formTheName(value?.first_name, value?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
							</Avatar>
							<Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
								{formTheName(value?.first_name, value?.last_name)}
							</Typography>
						</div>
					) : (
						<p className={classes.noneSection}>None</p>
					)}
				</div>
			)}
		</>
	)
}

PriorityAutocomplete.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	isAssigneeListOpen: PropTypes.bool,
	token: PropTypes.string,
	toggleAssigneeList: PropTypes.func,
	classes: PropTypes.object,
	options: PropTypes.array,
	name: PropTypes.string,
	disabled: PropTypes.bool,
}

const DuedateForm = ({ value, onChange, isAssigneeListOpen, toggleAssigneeList, classes, name, error, disabled }) => {
	return (
		<>
			{isAssigneeListOpen || !_.isEqual(value, null) || !_.isEmpty(value) || error ? (
				<div className={classes.dateTime}>
					<DateTimePicker
						fullWidth
						minDateMessage={<></>}
						inputVariant='outlined'
						value={value}
						size={'small'}
						name={name}
						error={error}
						margin='dense'
						clearable
						autoFocus
						disabled={disabled}
						disablePast={true}
						format={'DD MMM YYYY, h:mm:ss A'}
						onChange={(date) => {
							onChange('', date)
							toggleAssigneeList()
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton size='small'>
										<Icon>date_range</Icon>
									</IconButton>
								</InputAdornment>
							),
						}}
						className={classes.inputText}
					/>
					{error && <FormHelperText error>Due date should not be in past</FormHelperText>}
				</div>
			) : (
				<p onClick={disabled ? null : toggleAssigneeList} className={classes.noneSection}>
					None
				</p>
			)}
		</>
	)
}

DuedateForm.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	isAssigneeListOpen: PropTypes.bool,
	token: PropTypes.string,
	toggleAssigneeList: PropTypes.func,
	classes: PropTypes.object,
	options: PropTypes.array,
	name: PropTypes.string,
	error: PropTypes.bool,
	disabled: PropTypes.bool,
}

const ListItem = ({ name = '', email = '', isSelected = false, imgSrc, ...others }) => {
	const classes = useStyles()
	return (
		<Grid container alignItems='center' style={{ flexWrap: 'nowrap' }}>
			<Avatar alt={name} src={imgSrc} size='small' className={classes.userPic} style={{ backgroundColor: stringToHslColor(name) }}>
				{_.slice(_.words(name), 0, 1).reduce((a, t) => (a += t[0]), '')}
			</Avatar>
			<Grid item xs style={{ maxWidth: `calc(100% - (36px + 8px))` }}>
				<Typography variant='body2' className={classes.optionTextEllipsis} title={name}>
					{name}
				</Typography>
				<Typography variant='body2' color='textSecondary' title={email} className={classes.optionTextEllipsis}>
					{email}
				</Typography>
			</Grid>
		</Grid>
	)
}

ListItem.propTypes = {
	name: PropTypes.string,
	email: PropTypes.string,
	isSelected: PropTypes.bool,
	imgSrc: PropTypes.string,
}

export default StickyAutoComplete
