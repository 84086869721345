import {
	Container,
	Icon,
	makeStyles,
	Typography,
	Fab,
	Grow,
	Grid,
	Badge,
	Button,
	useTheme,
	Link,
	TablePagination,
	IconButton,
} from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classnames from 'classnames'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import Header from './header'
import { useDispatch, useSelector } from 'react-redux'
import { resetGlass } from 'services/glass/actions'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { STICKY_STATUS_ORDER } from 'constants/index'
import { API_ENDPOINTS } from 'constants/index'
import { io } from 'socket.io-client'
import SocketContext from './socket-context'
import socketEventsWatcher from './socket-events-watcher'
import FilterMenuList from './FilterMenu'
import StickySlider from './StickySlider'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { convertGmtToLocalTime, convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
import Axios from 'axios'
import moment from 'moment'
import Table from './Table'
import FeedView from 'components/FeedView'
// new imports
import MyTaskApi from 'services/myTask/api'
import { getMytaskFilter, setMytaskFilter, getMytaskStickies } from 'services/myTask/actions'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 'calc(100vh - 0px)',
		position: 'relative',
		boxSizing: 'border-box',
		'& textarea': {
			font: 'inherit',
		},
		'& a.sc-iemWCZ.ivkEkN': {
			textAlign: 'center',
			textDecoration: 'none',
		},
	},
	errorMsg: {
		textAlign: 'center',
	},
	errContainer: {
		padding: theme.spacing(4),
		textAlign: 'center',
	},
	errIcon: {
		color: theme.palette.error.main,
		fontSize: '2.5em',
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		[theme.breakpoints.down(337)]: {
			minWidth: `-webkit-fill-available`,
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		[theme.breakpoints.down(337)]: {
			width: '100%',
		},
		opacity: 1,
		// zIndex: 1,
		zIndex: 1100,
		marginTop: '1px',
		[theme.breakpoints.down(600)]: {
			marginTop: '-3px',
		},
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
	},

	boardContainer: {
		width: `calc(100% - ${MENU_WIDTH}px)`,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		boxSizing: 'border-box',
		position: 'relative',
	},

	mobileBoardContainerOpened: {
		[theme.breakpoints.down('xs')]: {
			'&::after': {
				position: 'absolute',
				width: '100%',
				height: '100%',
				content: '" "',
				background: 'rgba(0,0,0,0.5)',
				zIndex: 1,
			},
		},
		paddingBottom: theme.spacing(0),
	},
	containerRoot: {
		overflow: 'hidden',
	},
	rootContainer: {
		height: '100%',
	},
	tableRoot: {
		padding: theme.spacing(2),
		marginTop: '40px',
	},
	exportBtn: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'end',
		marginBottom: theme.spacing(2),
	},
	allpagesLink: {
		fontSize: 14,
		display: 'block',
		marginTop: theme.spacing(1),
		cursor: 'pointer',
	},
	selectedContent: {
		display: 'block',
		marginTop: theme.spacing(1),
	},
	feedView: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '60px',
		[theme.breakpoints.down(600)]: {
			marginTop: '45px',
		},
	},
}))

const converTimeToGMT = (value) => {
	return moment(value).isValid() ? convertLocalTimeToGmtStr(value) : value
}

const convertTimeToLocal = (value) => {
	return moment(value).isValid() ? convertGmtToLocalTime(value) : value
}

const GlassX = ({ history, match, location }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const params = useParams()
	const socketIO = useRef()
	const [socketState, setSocketState] = useState({ isSocketConnected: false, sessionId: '' })
	const [selectedView, setSelectedView] = React.useState(0)
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const token = useSelector((state) => _.get(state, 'session.authToken'))
	const [param, setParam] = useState({ search: '', offset: 0, limit: 10 })
	const [data, setData] = useState({ loading: false, myTaskStickies: [], totalCount: 0, status: '', message: '' })
	const [dataEmpty, setDataEmpty] = useState(false)
	const [filterList, setFilterList] = useState({ data: [], status: '', message: '' })
	// const cancelExecutor = useRef()
	const cancelExecutor = useRef(undefined)
	const { loading, myTaskStickies, totalCount, status, message } = data

	const { isSocketConnected, sessionId } = socketState

	const reduxFilters = useSelector((state) => _.get(state, 'mytask.selectedFilters', {}))
	const [filter, setFilter] = useState({ searchText: '', filterData: {} })
	const { filterData } = filter
	const keysToRemove = ['sort_by', 'sort_by_order']
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(_.omit(filterData, keysToRemove), _.isEmpty)), [filterData])
	const [rowSelected, setRowSelected] = useState({ glassCode: '', stickyCode: '' })
	// const [pageQuery, setPageQuery] = useUrlSearchParams(
	// 	{ offset: 0, limit: 10, search_text: '' },
	// 	{ offset: Number, limit: Number, search_text: String }
	// )
	const [reload, setReload] = useState(false)

	const [sortingDetails, setSortingDetails] = useState({
		sort_by: !_.isEmpty(reduxFilters?.sort_by) ? reduxFilters?.sort_by : null,
		sort_by_order: reduxFilters?.sort_by_order,
	})
	const sortableFields = [
		{ id: 'sorting_sticky_code', name: 'Task Code' },
		{ id: 'sort_due_date', name: 'Due Date and Time' },
		{ id: 'created_date', name: 'Created Date' },
		{ id: 'modified_date', name: 'Modified Date' },
		{ id: 'sorting_priority', name: 'Priority' },
		{ id: 'sticky_status', name: 'Status' },
		{ id: 'assignee_name', name: 'Assignee' },
		{ id: 'reporting_name', name: 'Reporting To' },
		{ id: 'glass_name', name: 'Board Name' },
	]

	useEffect(() => {
		setSortingDetails({ sort_by: !_.isEmpty(reduxFilters?.sort_by) ? reduxFilters?.sort_by : null, sort_by_order: reduxFilters?.sort_by_order })
	}, [reduxFilters])

	useEffect(() => {
		if (!_.isEmpty(reduxFilters)) {
			setFilter((prevState) => ({
				...prevState,
				filterData: {
					...reduxFilters,
					due_date: reduxFilters?.due_date && {
						from: convertTimeToLocal(reduxFilters?.due_date?.from),
						to: convertTimeToLocal(reduxFilters?.due_date?.to),
					},
					created_date: reduxFilters?.created_date && {
						from: convertTimeToLocal(reduxFilters?.created_date?.from),
						to: convertTimeToLocal(reduxFilters?.created_date?.to),
					},
				},
			}))
		}
	}, [reduxFilters])

	const getTableData = useCallback((glassDetails) => {
		let stickyArr = []
		_.map(glassDetails, (sticky) => {
			stickyArr.push({
				...sticky,
				assign_first_name: sticky?.assigned_to?.first_name,
				reporting_first_name: sticky?.reporting_to?.first_name,
				paneName: sticky?.pane_name,
				status: _.indexOf(STICKY_STATUS_ORDER, sticky?.sticky_status?.status),
				parentCode: sticky?.parent_sticky_code,
			})
		})
		return stickyArr
	}, [])

	const getStickyListView = useCallback(
		(search, offset, limit, filterData) => {
			if (_.isEqual(selectedView, 0) && !dataEmpty) {
				setData((prevState) => ({ ...prevState, loading: true }))
			} else {
				setData({ loading: true, myTaskStickies: [], totalCount: 0 })
			}
			const apiData = { search_text: search, offset, limit, filter_data: filterData }
			// if (cancelExecutor.current !== undefined) {
			// 	cancelExecutor.current()
			// }
			MyTaskApi.fetchMytaskStickies(apiData, cancelExecutor)
				.then((res) => {
					const layout = _.get(res, 'data.data.layout') === 'feed' ? 0 : 1
					setSelectedView(layout)
					if (layout === 0) {
						setData((prevState) => ({
							loading: false,
							myTaskStickies:
								prevState.myTaskStickies.length === 0
									? _.get(res, 'data.data.stickies', [])
									: _.uniqBy([...prevState.myTaskStickies, ..._.get(res, 'data.data.stickies', [])], 'sticky_code'),
							totalCount: _.get(res, 'data.data.total_count', 0),
							status: _.get(res, 'data.status', ''),
							message: _.get(res, 'data.message', ''),
						}))
						setDataEmpty(false)
					} else {
						setData((prevState) => ({
							loading: false,
							myTaskStickies: _.get(res, 'data.data.stickies', []),
							totalCount: _.get(res, 'data.data.total_count', 0),
							status: _.get(res, 'data.status', ''),
							message: _.get(res, 'data.message', ''),
						}))
						setDataEmpty(false)
					}
				})
				.catch((err) => {
					if (!Axios.isCancel(err))
						if (selectedView === 0) {
							setData((prevState) => ({ ...prevState, loading: false }))
							setDataEmpty(false)
						} else {
							setData({ loading: false, myTaskStickies: [], totalCount: 0 })
							setDataEmpty(false)
						}
					// console.log(err, 'err')
				})
		},
		[setSelectedView, selectedView, myTaskStickies, param.search, param.limit, param.offset, data, setData, dataEmpty, setDataEmpty]
	)

	const tableData = getTableData(myTaskStickies)

	const getMytaskFiltersList = () => {
		MyTaskApi.getMytaskFiltersList(sessionId)
			.then((res) => {
				setFilterList({ data: _.get(res, 'data.data', []), message: _.get(res, 'data.message', ''), status: _.get(res, 'data.status', '') })
			})
			.catch((err) => {
				// console.log(err, 'err')
			})
	}

	const sortFilter = useCallback(
		(field, type) => {
			let formatedData = { ...reduxFilters, sort_by: field, sort_by_order: type }
			setFilter((prevState) => ({ ...prevState, filterData: formatedData }))
			let data = _.omitBy(formatedData, _.isEmpty)
			setFilter((prevState) => {
				dispatch(
					setMytaskFilter(
						sessionId,
						data,
						param.search,
						(res) => {
							// if (isListView) {
							// 	getStickyListView(param.search, param.offset, param.limit)
							// }
						},
						(err) => {
							dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
						}
					)
				)
				getStickyListView(param.search, 0, param.limit, data)
				return { ...prevState, filterData: formatedData }
			})
		},
		[reduxFilters, param.search]
	)

	const changeView =
		// useCallback(
		(index) => {
			setDataEmpty(true)
			setSelectedView(index)
			// setData({ loading: true, myTaskStickies: [], totalCount: 0 })
			setParam({ ...param, offset: 0 })
			// setPageQuery({ ...pageQuery, offset: 0 })
			// getStickyListView(param.search, 0, 10)
		}
	// 	[param, pageQuery, data, selectedView]
	// )

	const onChangePage = (e, pageNo) => {
		setParam({ ...param, offset: pageNo * param.limit })
		// setPageQuery({ ...pageQuery, offset: pageNo * pageQuery.limit })
	}

	const changeRowPerPage = (e) => {
		setParam({ ...param, limit: e.target.value, offset: 0 })
		// setPageQuery({ ...pageQuery, limit: e.target.value, offset: 0 })
	}

	useEffect(() => {
		getStickyListView(param.search, param.offset, param.limit)
		setReload(false)
	}, [param.search, param.limit, param.offset, _.isEqual(reload, true), selectedView])

	useEffect(() => {
		getMytaskFiltersList()
	}, [])

	useEffect(() => {
		dispatch(getMytaskFilter(sessionId))
	}, [])

	const onSearchChange = (searchText) => {
		setData({ loading: true, myTaskStickies: [], totalCount: 0, status: '', message: '' })
		setParam({ ...param, search: searchText, offset: 0 })
		// setPageQuery({ ...pageQuery, search_text: searchText, offset: 0 })
	}

	const updateFilter = useCallback(
		(value) => {
			setData((prevState) => ({ ...prevState, loading: true, myTaskStickies: [], totalCount: 0, status: '', message: '' }))
			let formatedData = {
				...value,
				due_date: !_.isEmpty(value?.due_date) && {
					from: converTimeToGMT(value?.due_date?.from),
					to: converTimeToGMT(value?.due_date?.to),
				},
				created_date: !_.isEmpty(value?.created_date) && {
					from: converTimeToGMT(value?.created_date?.from),
					to: converTimeToGMT(value?.created_date?.to),
				},
			}
			setFilter((prevState) => ({ ...prevState, filterData: formatedData }))
			let data = _.omitBy(formatedData, _.isEmpty)
			setFilter((prevState) => {
				dispatch(
					setMytaskFilter(
						sessionId,
						data,
						param.search,
						(res) => {
							// if (isListView) {
							// 	getStickyListView(param.search, param.offset, param.limit)
							// }
						},
						(err) => {
							dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
						}
					)
				)
				getStickyListView(param.search, 0, param.limit, data)
				return { ...prevState, filterData: value }
			})
		},
		[dispatch, sessionId, param.search, param.limit]
	)

	const handleCloseSlider = () => {
		history.push(`/mytasks`)
		// setRowSelected({ glassCode: '', stickyCode: '' })
	}

	useEffect(() => {
		return () => {
			setMenuProps((prev) => ({
				...prev,
				isOpen: false,
			}))
			dispatch(resetGlass())
		}
	}, [dispatch])

	//OPEN SOCKET CONNECTION
	useEffect(() => {
		socketIO.current = io(API_ENDPOINTS.GLASS_SOCKET, {
			transports: ['websocket'],
			query: `access_token=${token}`,
		})

		//On Authentication of the user authToken
		socketIO.current.on('onAuthentication', (resp) => {
			if (resp?.status === 'success') {
				setSocketState({ sessionId: socketIO.current.id, isSocketConnected: true })
			}
		})

		socketEventsWatcher(socketIO, dispatch, history)

		socketIO.current.on('disconnect', () => {
			setSocketState({ sessionId: '', isSocketConnected: false })
		})

		return () => {
			socketIO.current.disconnect()
		}
	}, [dispatch, token, history])

	const onRowClicked = (e, row) => {
		const stickyCode = row?.sticky_code
		const glassCode = row?.glass_code
		setRowSelected({ glassCode, stickyCode })
		stickyCode && history.push(`/mytasks/view/${glassCode}/${stickyCode}`)
	}

	const headers = [
		{
			name: 'Task code',
			dataKey: 'sticky_code',
			align: 'left',
			style: {
				width: '170px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
		},
		{
			name: 'Board name',
			dataKey: 'glass_name',
			align: 'left',
			style: {
				width: '220px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isGlassXName: true,
		},
		{
			name: 'Pane Name',
			dataKey: 'paneName',
			align: 'left',
			style: {
				width: '175px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
		},
		{
			name: 'Task Title',
			dataKey: 'sticky_title',
			align: 'left',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isStickyTitle: true,
		},
		{
			name: 'Assignee',
			dataKey: 'assign_first_name',
			align: 'center',
			style: {
				width: '180px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isAssignee: true,
		},
		{
			name: 'Timer',
			dataKey: 'sticky_code',
			align: 'center',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isTimer: true,
		},
		{
			name: 'Due Date & Time',
			dataKey: 'due_date',
			align: 'left',
			style: {
				width: '170px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isDueDate: true,
		},
		{
			name: 'Reporting to',
			dataKey: 'reporting_first_name',
			align: 'center',
			style: {
				width: '200px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isReportingTo: true,
		},
		{
			name: 'Status',
			dataKey: 'status',
			align: 'left',
			style: {
				width: '100px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isStatus: true,
		},
		{
			name: 'Priority',
			dataKey: 'priority',
			align: 'center',
			style: {
				width: '160px',
				fontSize: '12px',
				color: 'rgb(68, 68, 79)',
			},
			isPriority: true,
		},
	].filter(Boolean)

	return (
		<div className={classnames('page-content', classes.root)}>
			<SocketContext.Provider value={socketState}>
				{_.isEqual(status, 'failed') && !loading ? (
					<Container className={classes.errContainer} maxWidth={'md'}>
						<Icon className={classes.errIcon}>error</Icon>
						<Typography variant='body2' className={classes.errorMsg}>
							{message}
						</Typography>
					</Container>
				) : (
					<Grid container className={classnames(classes.containerRoot)}>
						<div
							className={classnames(classes.boardContainer, {
								[classes.mobileBoardContainerOpened]: filterMenuProps.isOpen,
							})}
						>
							<Header
								data={tableData}
								// isLoading={loading}
								filterMenuProps={filterMenuProps}
								onSearchChange={onSearchChange}
								totalstickies={totalCount}
								sortable_fields={sortableFields}
								sortingDetails={sortingDetails}
								setSortingDetails={setSortingDetails}
								sortFilter={sortFilter}
								selectedView={selectedView}
								setSelectedView={setSelectedView}
								changeView={changeView}
								setData={setData}
								setDataEmpty={setDataEmpty}
								setParam={setParam}
							/>
							{_.isEqual(selectedView, 1) ? (
								<div className={classes.tableRoot}>
									<div className={classes.exportBtn}></div>
									<Table
										idKey='sticky_code'
										history={history}
										showLoading={loading}
										data={tableData}
										headers={headers}
										rowCount={totalCount}
										totalCount={totalCount}
										page={param.offset / param.limit}
										rowsPerPage={param.limit}
										onClickRow={onRowClicked}
										setReload={setReload}
									></Table>
									{!loading && (
										<TablePagination
											component='div'
											count={totalCount}
											page={param.offset / param.limit}
											onPageChange={onChangePage}
											rowsPerPage={param.limit}
											onRowsPerPageChange={changeRowPerPage}
										/>
									)}
								</div>
							) : (
								<div className={classes.feedView}>
									<FeedView
										myTaskStickies={myTaskStickies}
										onSliderClick={onRowClicked}
										showLoading={loading}
										token={token}
										history={history}
										setData={setData}
										data={data}
										totalCount={totalCount}
										getStickyListView={getStickyListView}
										setParam={setParam}
										param={param}
										isSocketConnected={isSocketConnected}
										socketIO={socketIO}
										// setPageQuery={setPageQuery}
									/>
								</div>
							)}
						</div>
						<StickySlider
							match={match}
							onClose={handleCloseSlider}
							glassCode={rowSelected?.glassCode}
							sessionId={sessionId}
							socketRef={socketIO}
							isSocketConnected={isSocketConnected}
							authToken={token}
							history={history}
							location={location}
						/>
						<Grow in={!filterMenuProps.isOpen} unmountOnExit>
							<Fab
								className={classes.fab}
								color='primary'
								size='small'
								onClick={() => {
									setMenuProps((prev) => ({
										...prev,
										isOpen: true,
									}))
								}}
							>
								{isFilterActive ? (
									<Badge color='error' badgeContent={''} variant='dot'>
										<Icon>filter_list</Icon>
									</Badge>
								) : (
									<Icon>filter_list</Icon>
								)}
							</Fab>
						</Grow>
						{filterMenuProps.isOpen && (
							// <div className={classnames(classes.menuContainer, { [classes.openMenu]: filterMenuProps.isOpen })}>
							<FilterMenuList
								token={token}
								activeFilters={filter?.filterData}
								setMenuProps={setMenuProps}
								updateFunc={updateFilter}
								filterList={filterList?.data}
							/>
							// </div>
						)}
					</Grid>
				)}
			</SocketContext.Provider>
		</div>
	)
}

export default GlassX
